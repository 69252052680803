import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import { ArrowBack } from "tabler-icons-react";
import { Container, SimpleGrid, Text, Stack, Title, Center, Card, Button } from "@mantine/core";

{
    /* load layout styles */
}
import ContactStyles from "../styles/contact_style";

const ContactPage = () => {
    const { classes } = ContactStyles();

    return (
        <Layout>
            <Container fluid className={classes.contact}>
                <Center pt="xl" pb="xl">
                    <Card shadow="sm" p="xl" radius="sm" withBorder>
                        <Title order={1}>Nederland Live V.O.F.</Title>
                        <Text py={20}>
                            Boterdiep 71, 9712LL
                            <br />
                            Groningen, The Netherlands
                        </Text>
                        <SimpleGrid cols={2} spacing="xs" verticalSpacing="xs">
                            <b>Phone:</b>
                            <div>
                                <Button variant="subtle" component="a" href="tel:+31880000077">
                                    +31 (0) 88 000 00 77
                                </Button>
                            </div>
                            <b>E-mail General:</b>
                            <div>
                                <Button variant="subtle" component="a" href="mailto:info@nederland.live">
                                    info-at-nederland-dot-live
                                </Button>
                            </div>
                            <b>E-mail Sales:</b>
                            <div>
                                <Button variant="subtle" component="a" href="mailto:sales@nederland.live">
                                    sales-at-nederland-dot-live
                                </Button>
                            </div>
                            <b>E-mail Postmaster:</b>
                            <div>
                                <Button variant="subtle" component="a" href="mailto:info@nederland.live">
                                    postmaster-at-nederland-dot-live
                                </Button>
                            </div>
                            <b>E-mail Abuse:</b>
                            <div>
                                <Button variant="subtle" component="a" href="mailto:abuse@nederland.live">
                                    abuse-at-nederland-dot-live
                                </Button>
                            </div>
                            <b>Chamber of Commerce:</b>
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;02068785
                                <Button variant="subtle" component="a" href="https://www.kvk.nl/zoeken/">
                                    (check)
                                </Button>
                            </div>
                            <b>VAT:</b>
                            <div>
                                &nbsp;&nbsp;&nbsp;&nbsp;NL818149772B01
                                <Button variant="subtle" component="a" href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation">
                                    (check)
                                </Button>
                            </div>
                        </SimpleGrid>

                        <Button component={Link} to="/" leftIcon={<ArrowBack />} variant="gradient" gradient={{ from: "orange", to: "yellow" }} fullWidth mt="md" radius="sm">
                            Show me that landing page again
                        </Button>
                    </Card>
                </Center>
            </Container>
        </Layout>
    );
};

export default ContactPage;
